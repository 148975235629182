import React from "react";
import "./MobileProjectList.css";
import CodingProject from "../CodingProject/CodingProject";
import { Element } from "react-scroll";

var projects = [];
//
function MobileProjectList() {
	return (
		<Element name="my-projects">
			<div className="mobileProjectList">
				<h1 className="sectionTitle">My iOS Projects</h1>
			</div>
		</Element>
	);
}

export default MobileProjectList;
