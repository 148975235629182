import React from "react";
import "./SWEProjectList.css";
import CodingProject from "../CodingProject/CodingProject";
import WWXImage from "./ProjectImages/WWXImage.png";
import TravelrImage from "./ProjectImages/TravelrImage.png";
import LabyrinthImage from "./ProjectImages/LabyrinthImage.png";
import SpellChampImage from "./ProjectImages/SpellChamp.png";
import PassTheTriviaImage from "./ProjectImages/PassTheTrivia.png";

var projects = [
	{
		title: "Pass The Trivia",
		description:
			"Pass The Trivia is a 2000 question single-player or multiplayer trivia game published June 2022. It is an independent iOS development project with over 1000 users in over 25 countries worldwide.",
		image: PassTheTriviaImage,
		buttonTitle: "SEE ON APP STORE",
		link: "https://apps.apple.com/us/app/pass-the-trivia/id1628448087",
	},
	{
		title: "Water Weather Stations",
		description:
			"Software engineering project partnered with the SEA Discovery Center in Poulsbo, WA, which monitors Puget Sound weather conditions using telemetry processing. Contains a website (Angular), API (Node.js/Express), mobile app (Flutter), and hardware (C). I held the position of mobile application team lead, where I managed and directed a team consisting of around 3-4 engineers.",
		image: WWXImage,
		buttonTitle: "SEE PRESENTATION",
		link: "https://docs.google.com/presentation/d/e/2PACX-1vSKb7Srb2hiHK0WY66PNNaVMQw1d3AC2uvMt29Szznj27K1UTZqP2y2ohJHf3fWWNqFpP3SFf_a8JX_/embed?start=false&loop=false&delayms=15000",
	},
	{
		title: "Spell Champ",
		description:
			"Spell Champ is a single-player spelling bee iOS game with over 1500 levels built using UIKit. Published in February 2023, it is used in over 7 countries worldwide. It is an independently developed iOS application built outside of any university coursework.",
		image: SpellChampImage,
		buttonTitle: "SEE ON APP STORE",
		link: "https://apps.apple.com/us/app/spell-champ-bee-a-champion/id1670776147",
	},
	{
		title: "travelr",
		description:
			"Vacation planner web application built using React for WWU's web development course. Development team consisted of 5 students, including myself. Used multiple APIs, including Yelp's and Google Maps (see Activities page, pictured), as well as a backend, primarily using MongoDB.",
		image: TravelrImage,
		buttonTitle: "SEE ON GITHUB",
		link: "https://github.com/leifjohanson/travelr",
	},
];
//
function SWEProjectList() {
	return (
		<div className="sweProjectList">
			<h1 className="sectionTitle">My SWE Projects</h1>
			<CodingProject project={projects[0]} />
			<CodingProject project={projects[1]} />
			<CodingProject project={projects[2]} />
			<CodingProject project={projects[3]} />
		</div>
	);
}

export default SWEProjectList;
